import { MutationTree } from 'vuex';
import StoreStateInterface from './store-state-interface';

const storeMutations: MutationTree<StoreStateInterface> = {
	setAppKeyMutation: (state, appKey) => {
		state.appKey = appKey;
	},

	setCategoriesMutation: (state, categories) => {
		state.categories = categories;
	},

	setProductsMutation: (state, products) => {
		state.products = products;
	},

	setProductStocksMutation: (state, productStocks) => {
		state.productStocks = productStocks;
	},

	setPageDataMutation: (state, pageData) => {
		state.pageData = pageData;
	},

	setUserMutation: (state, user) => {
		state.user = user;
	},

	setOrderMutation: (state, order) => {
		state.order = order;
	},

	setOrderNotesMutation: (state, orderNotes) => {
		state.orderNotes = orderNotes;
	},

	setOrdersPageDataMutation: (state, ordersPageData) => {
		state.ordersPageData = ordersPageData;
	},

	setOrdersMutation: (state, orders) => {
		state.orders = orders;
	},

	setStoreMutation: (state, stores) => {
		state.stores = stores;
	},

	setSearchParamsMutation: (state, searchParams) => {
		searchParams.timestamp = (+new Date());
		state.searchParams = searchParams;
	},

	readyToStartMutation: (state, readyToStart: boolean) => {
		state.readyToStart = readyToStart;
	},

	setBannerAreaHomeMutation: (state, bannerArea) => {
		state.bannerAreaHome = bannerArea;
	},

	setBannerAreaHomePrivateMutation: (state, bannerArea) => {
		state.bannerAreaHomePrivate = bannerArea;
	},

	setBannerAreaProductsMutation: (state, bannerArea) => {
		state.bannerAreaProducts = bannerArea;
	},

	setBannerAreaRegistrationMutation: (state, bannerArea) => {
		state.bannerAreaRegistration = bannerArea;
	},

	setSearchAidsMutation: (state, searchAids) => {
		state.searchAids = searchAids;
	},

	setProvincesMutation: (state, provinces) => {
		state.provinces = provinces;
	},

	setBrandsMutation: (state, brands) => {
		state.brands = brands;
	},

	setIsStoreOptionsShowedMutation: (state, isStoreOptionsShowed) => {
		state.isStoreOptionsShowed = isStoreOptionsShowed;
	},

	setUrlPreLoginMutation: (state, urlPreLogin) => {
		state.urlPreLogin = urlPreLogin;
	},

	setDisabledDatesMutation: (state, disabledDates) => {
		state.disabledDates = disabledDates;
	},

	setCustomerShippingFormMutation: (state, customerShippingForm) => {
		state.customerShippingForm = customerShippingForm;
	},

	setSecondaryCustomersMutation: (state, secondaryCustomers) => {
		state.secondaryCustomers = secondaryCustomers;
	},

	setSecondaryCustomersPageDataMutation: (state, secondaryCustomersPageData) => {
		state.secondaryCustomersPageData = secondaryCustomersPageData;
	},

	setProductTagsMutation: (state, productTags) => {
		state.productTags = productTags;
	},

	setProductTagsInitializedMutation: (state, productTagsInitialized) => {
		state.productTagsInitialized = productTagsInitialized;
	},

	setCollectTimeOptionsMutation: (state, collectTimeOptions) => {
		state.collectTimeOptions = collectTimeOptions;
	}
};
export default storeMutations;
