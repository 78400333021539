import { GetterTree } from 'vuex';
import StoreStateInterface from './store-state-interface';
import CcOrder from '@/main/webapp/resources/src/vue/domain/order/cc-order';

const storeGetters: GetterTree<StoreStateInterface, any> = {
	appKey: state => {
		return state.appKey;
	},

	categories: state => {
		return state.categories;
	},

	products: state => {
		return state.products;
	},

	productStocks: state => {
		return state.productStocks;
	},

	pageData: state => {
		return state.pageData;
	},

	user: state => {
		return state.user;
	},

	order: state => {
		return state.order;
	},

	orderNotes: state => {
		return state.orderNotes;
	},

	orders: state => {
		return state.orders;
	},

	ordersPageData: state => {
		return state.ordersPageData;
	},

	orderType: state => {
		return state.order ? state.order.type : null;
	},

	stores: state => {
		return state.stores;
	},

	searchParams: state => {
		return state.searchParams;
	},

	searchCategoryPaths: state => {
		return state.searchParams.categoryPaths;
	},

	searchDescriptionLike: state => {
		return state.searchParams.descriptionLike;
	},

	searchCustomSort: state => {
		return state.searchParams.customSort;
	},

	searchBrands: state => {
		return state.searchParams.brands;
	},

	searchAttribute: state => {
		return state.searchParams.attribute;
	},

	searchTags: state => {
		return state.searchParams.productTags;
	},

	readyToStart: state => {
		return state.readyToStart;
	},

	bannerAreaHome: state => {
		return state.bannerAreaHome;
	},

	bannerAreaHomePrivate: state => {
		return state.bannerAreaHomePrivate;
	},

	bannerAreaRegistration: state => {
		return state.bannerAreaRegistration;
	},

	bannerAreaProducts: state => {
		return state.bannerAreaProducts;
	},

	searchAids: state => {
		return state.searchAids;
	},

	provinces: state => {
		return state.provinces;
	},

	brands: state => {
		return state.brands;
	},

	isStoreOptionsShowed: state => {
		return state.isStoreOptionsShowed;
	},

	urlPreLogin: state => {
		return state.urlPreLogin;
	},

	disabledDates: state => {
		return state.disabledDates;
	},

	customerShippingForm: state => {
		return state.customerShippingForm;
	},

	secondaryCustomers: state => {
		return state.secondaryCustomers;
	},

	secondaryCustomersPageData: state => {
		return state.secondaryCustomersPageData;
	},

	productTags: state => {
		return state.productTags;
	},

	productTagsInitialized: state => {
		return state.productTagsInitialized;
	},

	collectTimeOptions: state => {
		return state.collectTimeOptions;
	}
};
export default storeGetters;
